.background {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    background-image: url("http://medescreen.co.za/wp-content/uploads/2024/05/the-sollution.webp");
    width: 100%;
    height: 100%;
    margin: 0% 0% 0% 0%;
    z-index: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-filter {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(5, 38, 34, 0.8);
    z-index: 1;
    background-position: center;
}


.voucher-container {
    position: absolute;
    width: 60%;
    background-color: #fff;
    border-radius: 28px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5% 20% 5% 20%;
    text-align: center;
    padding-bottom: 2%;
    border: solid 1px black;
}

.voucher-logo {
    position: relative;
    width: 40%;
    margin: 5% 30% 2% 30%;
}

.voucher-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    margin: 2% 0% 2% 0%;
}

.voucher-form {
    position: relative;
    width: 100%;
    margin: 1% 0% 1% 0%;
    display: block;
}

.voucher-form-container {
    position: relative;
    width: 90%;
    margin: 1% 5% 1% 5%;
    display: grid;
    grid-template-columns: 100%;
}

.input-group {
    position: relative;
    width: 50%;
    margin: 2% 25% 2% 25%;
}

.input-group p {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.input-group input {
    width: 80%;
    padding: 2% 2% 2% 3%;
    margin: 5% 10% 0% 10%;
    border: solid 1px black;
    border-radius: 20px;
}

.input-group button {
    width: 60%;
    margin: 4% 20% 2% 20%;
    padding: 3% 2% 3% 2%;
    background-color: #105249;
    color: white;
    border: solid 1px black;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.input-group button:hover {
    background-color: #36796e;
}

.scanner-container {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
    border: palevioletred solid 2px;
}

.voucher-submit {
    position: relative;
    width: 20%;
    padding: 1.5% 2% 1.5% 2%;
    margin: 2% 40% 2% 40%;
    background-color: #105249;
    color: white;
    border: solid 1px black;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    font-weight: bold;
}


.voucher-submit:hover {
    background-color: #36796e;
    transform: scale(0.8);
    border: solid 1px black;
}

.voucher-submit:active {
    background-color: #36796e;
    transform: scale(0.8);
    border: solid 1px black;
}


@media (max-width: 900px) {
    .voucher-container {
        position: absolute;
        width: 70%;
        background-color: #fff;
        border-radius: 28px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin: 8% 17% 5% 15%;
        text-align: center;
    }

    .voucher-container h1 {
        margin-bottom: 20px;
        font-size: 35px;
        color: #333;
        margin: 5% 0% 2% 0%;
    }

    .voucher-form {
        position: relative;
        width: 100%;
        margin: 1% 0% 1% 0%;
        display: block;
    }

    .voucher-form-container {
        position: relative;
        width: 90%;
        margin: 1% 5% 1% 5%;
        display: grid;
        grid-template-columns: 100%;
    }

    .input-group {
        position: relative;
        width: 90%;
        margin: 2% 5% 2% 5%;
    }

    .input-group p {
        display: block;
        margin-bottom: 7px;
        font-weight: bold;
    }

    .input-group input {
        width: 50%;
        padding: 4% 2% 4% 3%;
        margin: 5% 25% 0% 25%;
        border-radius: 20px;
    }

    .input-group button {
        width: 60%;
        margin: 4% 20% 2% 20%;
        padding: 3% 2% 3% 2%;
        background-color: #105249;
        color: white;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 14px;
    }

    .input-group button:hover {
        background-color: #36796e;
    }

    .scanner-container {
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
        border: palevioletred solid 2px;
    }

    .voucher-submit {
        position: relative;
        width: 20%;
        padding: 1.5% 2% 1.5% 2%;
        margin: 2% 40% 2% 40%;
        background-color: #105249;
        color: white;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.1s ease;
        font-size: 16px;
    }

    .voucher-submit:active {
        background-color: #36796e;
        transform: scale(0.8);
    }
}

@media (max-width: 700px) {
    .voucher-container {
        position: absolute;
        width: 80%;
        background-color: #fff;
        border-radius: 28px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin: 20% 10% 5% 10%;
        text-align: center;
    }

    .voucher-logo {
        position: relative;
        width: 60%;
        margin: 5% 20% 2% 20%;
    }

    .voucher-container h1 {
        font-size: 20px;
        color: #333;
        margin: 10% 0% 4% 0%;
    }

    .voucher-form {
        position: relative;
        width: 100%;
        margin: 1% 0% 1% 0%;
        display: block;
    }

    .voucher-form-container {
        position: relative;
        width: 90%;
        margin: 2% 5% 1% 5%;
        display: grid;
        grid-template-columns: 100%;
    }

    .input-group {
        position: relative;
        width: 90%;
        margin: 2% 3% 7% 3%;
        padding: 3% 2% 3% 2%;
    }

    .input-group p {
        display: block;
        font-size: 15px;
        font-weight: bold;
    }

    .input-group input {
        width: 80%;
        padding: 4% 3% 4% 3%;
        margin: 3% 10% 0% 10%;
        border-radius: 20px;
        text-align: center;
        font-size: 16px;

    }

    .input-group button {
        width: 50%;
        margin: 3% 25% 2% 25%;
        padding: 4% 2% 4% 2%;
        background-color: #105249;
        color: white;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .input-group button:hover {
        background-color: #36796e;
    }

    .scanner-container {
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
        border: palevioletred solid 2px;
    }

    .voucher-submit {
        position: relative;
        width: 40%;
        padding: 3.5% 2% 3.5% 2%;
        margin: 4% 30% 2% 30%;
        background-color: #105249;
        color: white;
        font-weight: bold;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.1s ease;
    }

    .voucher-submit:active {
        background-color: #36796e;
        transform: scale(0.8);
    }
}

@media (max-width: 450px) {
    .voucher-container {
        position: absolute;
        width: 80%;
        background-color: #fff;
        border-radius: 28px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin: 20% 10% 5% 10%;
        text-align: center;
    }

    .voucher-logo {
        position: relative;
        width: 70%;
        margin: 5% 15% 2% 15%;
    }

    .voucher-container h1 {
        font-size: 20px;
        color: #333;
        margin: 7% 0% 4% 0%;
    }

    .voucher-form {
        position: relative;
        width: 100%;
        margin: 1% 0% 1% 0%;
        display: block;
    }

    .voucher-form-container {
        position: relative;
        width: 90%;
        margin: 2% 5% 1% 5%;
        display: grid;
        grid-template-columns: 100%;
    }

    .input-group {
        position: relative;
        width: 90%;
        margin: 2% 5% 7% 5%;
    }

    .input-group p {
        display: block;
        font-size: 15px;
        font-weight: bold;
    }

    .input-group input {
        width: 80%;
        padding: 3% 3% 2% 3%;
        margin: 5% 7% 0% 7%;
        border-radius: 20px;
    }

    .input-group button {
        width: 50%;
        margin: 4% 25% 2% 25%;
        padding: 4% 2% 4% 2%;
        background-color: #105249;
        color: white;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .input-group button:hover {
        background-color: #36796e;
    }

    .scanner-container {
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
        border: palevioletred solid 2px;
    }

    .voucher-submit {
        position: relative;
        width: 40%;
        padding: 3.5% 2% 3.5% 2%;
        margin: 6% 30% 2% 30%;
        background-color: #105249;
        color: white;
        font-weight: bold;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.1s ease;
    }

    .voucher-submit:active {
        background-color: #36796e;
        transform: scale(0.8);
    }
}