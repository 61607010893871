
.mes-patient-file-page { 
    width: 100%;
    height: auto; /* Ensure the height adjusts to content */
    padding-bottom: 20px; /* Add some padding at the bottom */
    position: relative; /* Switch to relative */
    background-color: #f3f2f2;
}


.mes-header {
    position: relative;
    width: 100%;
    margin: 0% 0% 2% 0%;
    border-radius: 28px;
}


.instruction{
    position: relative;
    text-align: center;
    color: #000000;
    font-size: 13px;
    margin-bottom: 3%;
}

.tagline{
    position: relative;
    text-align: center;
    color: #105249;
    margin: 0.6% 0% 1% 0% ;
    font-size: 20px;
}


.mes-logo {
    position: relative;
    width: 30%;
    margin: 2% 35% 0% 35%;
}


.loading-screen{
    position: relative;
    width: 100%;
    margin: 3% 0% 1% 0%; 
}


.loading-container{
    position: relative;
    width: 30%;
    height: auto;
    margin: 2% 35% 2% 35%;
}


.loader{
    position: relative;
    width: 60%; /* Adjust the size as needed */
    margin: 2% 20% 5% 20%;
    height: auto; /* Adjust the size as needed */
    animation: spin 3.5s ease-in-out infinite;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.loading-text {
    position: relative;
    font-size: 27px;
    text-transform: capitalize;
    color: #7c7c7c;
    text-align: center;
    opacity: 0;  /* Initial state invisible */
    white-space: nowrap;
    animation: fadeInOut 3.5s ease-in-out infinite;
    margin: 5% 0% 2% 0%;
}
 

/* Animation for fade-in and fade-out */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateX(-100%); /* Start from left */
  }
  20% {
    opacity: 1;
    transform: translateX(0); /* Fully visible and centered */
  }
  80% {
    opacity: 1;
    transform: translateX(0); /* Hold visibility */
  }
  100% {
    opacity: 0;
    transform: translateX(100%); /* Disappear to the right */
  }
}


.patient-details{
    position: relative;
    border: 2px solid #105249;
    border-radius: 8px;
    background-color: #ffffff;
    width: 36%;
    margin: 2% 30% 2% 30%;
    padding: 0.5% 2% 2% 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.patient-details-info{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 5%;
}


.patient-details p{
    position: relative;
    margin-top: 1%;
}


.patient-details-heading{
    position: relative;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    border: solid #989797 2px;
    border-radius: 8px;
    color: #000000;
    background-color: #d5d5d5;
    margin: 3% 2% 1% 2%;
    padding: 2% 1% 2% 1%;
    width: 94%;
}


.file-container{
    position: relative;
    width: 100%;
    margin: 3% 0% 1% 0%;

}


.main-heading{
    position: relative;
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    border: solid #E27635 2px;
    border-radius: 8px;
    color: #000000;
    background-color: #FFB54F;
    margin: 3% 2% 1% 2%;
    padding: 2% 1% 2% 1%;
    width: 94%;
}


.file-container h1{
    position: relative;
    text-align: center;
    margin: 2% 0% 1% 0%;
    font-size: 20px;
    font-weight: bold;
}


.test-container{
    position: relative;
    width: 40%;
    margin: 1% 30% 1% 30%;
}


.example-test-container{
    position: relative;
    width: 40%;
    margin: 3% 30% 4% 30%;
    padding-bottom: 2%;
    border-bottom: solid 1px #808080;
}

.example-test-date {
    cursor: pointer;
}


.example-test-score {
    text-align: center;
    font-weight: bold;
}

  
.example-triage-box {
    position: relative;
    width: 90%;
    height: 20px;
    margin: 0% 5% 0% 5%;
    display: inline-block;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
}


.example-test-summary {
    display: grid;
    align-items: center;
    grid-template-columns: 30% 30% 30% 10%;
    background-color: #105249;
    border: solid 1px #000000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: white;
    cursor: pointer;
    width: 96%;
    margin: 4% 0% 4% 0%;
    padding: 3% 2% 3% 2%;
    transition: transform 0.3s ease; 
}


.test-summary {
    display: grid;
    align-items: center;
    grid-template-columns: 30% 30% 30% 10%;
    background-color: #105249;
    border: solid 1px #000000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: white;
    cursor: pointer;
    width: 96%;
    margin: 4% 0% 4% 0%;
    padding: 3% 2% 3% 2%;
    transition: transform 0.3s ease; 
}


.test-summary-active {
    display: grid;
    align-items: center;
    grid-template-columns: 30% 30% 30% 10%;
    background-color: #CCE1DA;
    color: #105249;
    border: #105249 solid 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    width: 96%;
    margin: 4% 0% 4% 0%;
    padding: 3% 2% 3% 2%;
    transition: transform 0.3s ease; 
    transition: ease-in-out 0.3 scale(0.8);
}


.test-dropdown{
    transition: transform 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}


.test-dropdown-active{
    transform: rotate(180deg)   
}


.test-dropdown svg{
    width: 20px; /* Adjust size as needed */
    height: 20px;
    fill: rgb(255, 255, 255); /* Change this color as needed */

}


.test-dropdown-active svg{
    width: 20px; /* Adjust size as needed */
    height: 20px;
    fill: rgb(0, 0, 0); /* Change this color as needed */
}


.test-date {
    cursor: pointer;
}


.test-score {
    text-align: center;
    font-weight: bold;
}


.triage-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;   
}

  
.triage-box {
    position: relative;
    width: 90%;
    height: 20px;
    margin: 0% 5% 0% 5%;
    display: inline-block;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
}


.triage-label{
    text-align: center;
    font-weight: bold;
}


.triage-green {
    background-color: green;
}


.triage-red {
    background-color: red;
}


.triage-orange {
    background-color: orange;
}


.section {
    margin-bottom: 15px;
} 


.section-header {
    position: relative;
    width: 100%;
    cursor: pointer;
    border: solid #105249 2px;
    border-radius: 8px;
    color: #000000;
    background-color: #6E9A92;
    font-weight: bold;
    text-align: center;
    padding: 2% 0% 2% 0%;
    margin: 2% 0% 2% 0%;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 90% 10%;
    align-items: center;
}


.section-header:hover {
    transform: scale(0.95); /* Slight hover scaling */
    transition: transform 0.3s ease; /* Smooth transition on hover */
}


.section-header.active {
    transform: scale(0.9); /* Apply the scale when clicked */
    transition: transform 0.3s ease;
}


.section-header.reset {
    transform: scale(1); /* Return to normal scale */
    transition: transform 0.3s ease;
}


.section-header-active {
    display: none;
}


.section-header-text{
    position: relative;
    text-align: center;
    margin-left: 10%;
    font-weight: bold;
}


.dropdown-icon {
    transition: transform 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}


.icon-collapsed {
    transform: rotate(0deg); /* Default orientation */
}
  

.icon-expanded {
    transform: rotate(180deg); /* Rotates the icon when the section is expanded */
}
  

.dropdown-icon svg {
    width: 20px; /* Adjust size as needed */
    height: 20px;
    fill: rgb(0, 0, 0); /* Change this color as needed */
}


.section-content {
    position: relative;
    width: 96%;
    background-color: white;
    border-radius: 8px;
    padding: 2% 2% 2% 2%;
    border: #105249 solid 2px;
}
  

.test-details {
    position: relative;
    border: 2px solid #105249;
    border-radius: 8px;
    background-color: #CCE1DA;
    width: 96%;
    margin: 2% 0% 2% 0%;
    padding: 2% 2% 4% 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.test-section{
    position: relative;
}


.group-name{
    position: relative;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #000000;
    margin: 3% 2% 1% 2%;
    padding: 2% 1% 2% 1%;
    width: 94%;
    border-bottom: #000000 2px solid;
}


.subgroup-name{
    position: relative;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
}


.screen-grid{
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 40% 28% 32%;
}


.question-grid{
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 80% 20%;
}


.desc-container {
    position: relative;
    width: 40%;
    margin: 2% 30% 2% 30%;
    padding-bottom: 3%;
    border-bottom: solid 1px #808080;
}


.desc-container h3{
    position: relative;
    margin: 5% 0% 4% 0%;
    font-size: 22px;
    text-align: center;
    color: #000000;
}


.desc {
    position: relative;
    width: 90%;
    background-color: white;
    padding: 0% 2% 0% 2%;
    border: solid 2px #105249;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 3% 3% 2% 3%;
}


.desc-content {
    position: relative;
    width: 100%;
    font-size: 18px;
    text-align: center;
}


.color-display{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 33.3333% 33.3333% 33.3333%;
    margin: 3% 0% 2% 0%;
}


.colors-red{
    position: relative;
    width: 70%;
    margin: 1% 15% 2% 15%;
    background-color: #ff0000;
    color: #ff0000;
}


.colors-orange{
    position: relative;
    width: 70%;
    margin: 1% 15% 2% 15%;
    background-color: #ffa500;
    color: #ffa500;
}


.colors-green{
    position: relative;
    width: 70%;
    margin: 1% 15% 2% 15%;
    background-color: #008000;
    color: #008000;
}
  
.mes-file-footer {
    width: 100%;
    padding: 2% 0%;
    text-align: center;
    margin-top: 30px; /* Ensure some space between content and mes-file-footer */
    position: relative; /* Use relative positioning */
    color: #7f7f7f;
}


/* -------------------------- */
/* --------- MOBILE --------- */
/* -------------------------- */


@media (max-width: 470px){

    .mes-patient-file-page { 
        width: 100%;
        height: auto; /* Ensure the height adjusts to content */
        padding-bottom: 50px; /* Add some padding at the bottom */
        position: relative; /* Switch to relative */
        background-color: #f3f2f2;
    }


    .mes-header {
        position: relative;
        width: 100%;
        margin: 2% 0% 6% 0%;
    }

    .instruction{
        position: relative;
        text-align: center;
        color: #000000;
        font-size: 13px;
        margin-bottom: 7%;
    }


    .tagline{
        position: relative;
        text-align: center;
        color: #105249;
        margin: 3% 0% 1% 0% ;
        font-size: 16px;
    }


    .mes-logo {
        position: relative;
        width: 80%;
        margin: 2% 10% 0% 10%;
    }


    .loading-screen{
        position: relative;
        width: 100%;
        margin: 20% 0% 1% 0%; 
    }


    .loading-container{
        position: relative;
        width: 60%;
        height: auto;
        margin: 2% 20% 2% 20%;
    }


    .loader{
        position: relative;
        width: 60%; /* Adjust the size as needed */
        margin: 2% 20% 5% 20%;
        height: auto; /* Adjust the size as needed */
        animation: spin 3.5s ease-in-out infinite;
    }


    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }


    .loading-text {
        position: relative;
        font-size: 17px;
        text-transform: capitalize;
        color: #7c7c7c;
        text-align: center;
        opacity: 0;  /* Initial state invisible */
        white-space: nowrap;
        animation: fadeInOut 3.5s ease-in-out infinite;
        margin: 5% 0% 2% 0%;
    }
    

    @keyframes fadeInOut {
        0% {
        opacity: 0;
        transform: translateX(-100%); /* Start from left */
        }
        20% {
        opacity: 1;
        transform: translateX(0); /* Fully visible and centered */
        }
        80% {
        opacity: 1;
        transform: translateX(0); /* Hold visibility */
        }
        100% {
        opacity: 0;
        transform: translateX(100%); /* Disappear to the right */
        }
    }


    .patient-details{
        position: relative;
        border: 2px solid #105249;
        border-radius: 8px;
        background-color: #ffffff;
        width: 76%;
        margin: 5% 10% 4% 10%;
        padding: 2% 2% 2% 2%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    

    .patient-details-info{
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        margin-top: 5%;
    }
    

    .patient-details p{
        position: relative;
        margin-top: 1%;
    }
    

    .patient-details-heading{
        position: relative;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        border: solid #989797 2px;
        border-radius: 8px;
        color: #000000;
        background-color: #d5d5d5;
        margin: 3% 2% 1% 2%;
        padding: 2% 1% 2% 1%;
        width: 94%;
    }


    .example-test-container{
        position: relative;
        width: 80%;
        margin: 6% 10% 8% 10%;
        padding-bottom: 4%;
        border-bottom: solid 1px #808080;
    }


    .example-test-date {
        cursor: pointer;
        font-size: 13px;
    }
    
    
    .example-test-score {
        text-align: center;
        font-weight: bold;
        font-size: 13px;
    }
    
      
    .example-triage-box {
        position: relative;
        width: 90%;
        height: 17px;
        padding: 2px 1px 2px 1px;
        margin: 0% 5% 0% 5%;
        display: inline-block;
        border-radius: 4px;
        text-align: center;
        font-weight: bold;
        font-size: 13px;
    }
    
    
    .example-test-summary {
        display: grid;
        align-items: center;
        grid-template-columns: 33.333% 33.333% 33.333%;
        background-color: #105249;
        border: solid 1px #000000;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        color: white;
        cursor: pointer;
        width: 96%;
        margin: 4% 0% 4% 0%;
        padding: 3% 2% 3% 2%;
        transition: transform 0.3s ease; 
    }


    .file-container{
        position: relative;
        width: 100%;
        margin: 3% 0% 1% 0%;
    }
    
    
    .file-container h1{
        position: relative;
        text-align: center;
        margin: 2% 0% 1% 0%;
        font-size: 20px;
        font-weight: bold;
    }
    
    
    .test-container{
        position: relative;
        width: 80%;
        margin: 1% 10% 1% 10%;
    }
    
    
    .test-date {
        color: white;
        cursor: pointer;
        width: 100%;
        margin: 4% 0% 4% 0%;
        padding: 3% 0% 3% 0%;
    }
    
     
    .test-summary {
        display: grid;
        align-items: center;
        grid-template-columns: 35% 25% 30% 10%;
        background-color: #105249;
        border: solid 1px #000000;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        color: white;
        cursor: pointer;
        width: 96%;
        margin: 5% 0% 5% 0%;
        padding: 3% 2% 3% 2%;
        transition: transform 0.3s ease; 
    }
    
    
    .test-summary-active {
        display: grid;
        align-items: center;
        grid-template-columns: 35% 25% 30% 10%;
        background-color: #CCE1DA;
        color: #105249;
        border: #105249 solid 2px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        cursor: pointer;
        width: 96%;
        margin: 4% 0% 4% 0%;
        padding: 3% 2% 3% 2%;
        transition: transform 0.3s ease; 
        transition: ease-in-out 0.3 scale(0.8);
    }
    
    
    .test-dropdown{
        transition: transform 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .test-dropdown-active{
        transform: rotate(180deg)   
    }
    
    
    .test-dropdown svg{
        width: 20px; /* Adjust size as needed */
        height: 20px;
        fill: rgb(255, 255, 255); /* Change this color as needed */
    
    }
    
    
    .test-dropdown-active svg{
        width: 20px; /* Adjust size as needed */
        height: 20px;
        fill: rgb(0, 0, 0); /* Change this color as needed */
    }
      
    
    .test-details {
        position: relative;
        border: 2px solid #105249;
        border-radius: 8px;
        background-color: #CCE1DA;
        width: 96%;
        margin: 2% 0% 2% 0%;
        padding: 2% 2% 3% 2%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }


    .section-content {
        position: relative;
        width: 96%;
        background-color: white;
        border-radius: 8px;
        padding: 4% 2% 4% 2%;
        border: #105249 solid 2px;
    }
    
    
    .test-section{
        position: relative;
    }
    
    
    .group-name{
        position: relative;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        border: solid #105249 2px;
        border-radius: 8px;
        color: #000000;
        background-color: #9DBEB6;
        margin: 9% 2% 1% 2%;
        padding: 2% 1% 2% 1%;
        width: 94%;
    }
    
    
    .subgroup-name{
        position: relative;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        text-decoration: underline;
    }
    
    
    .screen-grid{
        position: relative;
        display: grid;
        width: 100%;
        grid-template-columns: 40% 30% 30%;
    }
    
    
    .question-grid{
        position: relative;
        display: grid;
        width: 100%;
        grid-template-columns: 80% 20%;
    }
    

    .desc-container {
        position: relative;
        width: 80%;
        margin: 8% 10% 5% 10%;
        padding-bottom: 6%;
        border-bottom: solid 1px #808080;    
    }
    
    
    .desc-container h3{
        position: relative;
        margin: 5% 0% 4% 0%;
        font-size: 20px;
        text-align: center;
        color: #000000;
    }
    
    
    .desc {
        position: relative;
        width: 90%;
        background-color: white;
        padding: 0% 2% 0% 2%;
        border: solid 2px #105249;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin: 3% 3% 2% 3%;
    }
    
    
    .desc-content {
        position: relative;
        width: 100%;
        font-size: 14px;
        text-align: center;
    }
    
    
    .color-display{
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 33.3333% 33.3333% 33.3333%;
        margin: 3% 0% 2% 0%;
    }
    
    
    .colors-red{
        position: relative;
        width: 70%;
        margin: 1% 15% 2% 15%;
        background-color: #ff0000;
        color: #ff0000;
    }
    
    
    .colors-orange{
        position: relative;
        width: 70%;
        margin: 1% 15% 2% 15%;
        background-color: #ffa500;
        color: #ffa500;
    }
    
    
    .colors-green{
        position: relative;
        width: 70%;
        margin: 1% 15% 2% 15%;
        background-color: #008000;
        color: #008000;
    }
    
      
    .mes-file-footer {
        width: 100%;
        padding: 2% 0%;
        text-align: center;
        margin-top: 30px; /* Ensure some space between content and mes-file-footer */
        position: relative; /* Use relative positioning */
        color: #7f7f7f;
    }
    

}