.patient-file-background {
    display: block;
    background-image: url("http://medescreen.co.za/wp-content/uploads/2024/04/hero-image.webp");
    width: 100%;
    min-height: 100vh; /* Ensures the background image covers the full viewport */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
}


.file-background-filter {
    display: block;
    width: 100%;
    min-height: 100vh; /* Same change here to cover the entire viewport */
    background-color: rgba(5, 38, 34, 0.8); /* Ensures a dark overlay on top of the background */
}


.patient-file-page { 
    width: 100%;
    height: auto; /* Ensure the height adjusts to content */
    padding-bottom: 10px; /* Add some padding at the bottom */
    position: relative; /* Switch to relative */
}


.header {
    position: relative;
    width: 100%;
    margin: 0% 0% 2% 0%;
    border-radius: 28px;
}


.header p{
    position: relative;
    text-align: center;
    color: #ffffff;
    margin: 1% 0% 1% 0% ;
}


.mes-logo {
    position: relative;
    width: 24%;
    margin: 2% 38% 0% 38%;
}


.login-form {
    position: relative;
    width: 30%;
    margin: 5% 35% 2% 35%;
}


.Terms{
    position: relative;
    width: 100%;
    margin: 7% 0% 8% 0%;
    text-align: center;
    color: white;
    overflow-y: auto;
    height: 120px;
    font-size: 14px;
}


.patient-file-checkbox-container{
    position: relative;
    width: 82%;
    color: white;
    font-size: 15px;
    margin: 3% 9% 5% 9%;
}


.login-form-input {
    position: relative;
    width: 64%;
    margin: 2% 15% 1% 15%;
    border-radius: 8px;
    padding: 3% 3% 3% 3%;
    border: solid 1px black;
}


.login-form-input:active {
    border: #105249 solid 1.5px;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
}


.login-form h3{
    position: relative;
    margin: 2% 0% 8% 0%;
    font-size: 20px;
    text-align: center;
    color: #fff;
}


.login-btn {
    position: relative;
    width: 30%;
    margin: 2% 35% 2% 35%;
    padding: 2% 2% 2% 2%;
    background-color: #105249;
    color: white;
    font-size: 17px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    border: solid 1px black;
}


.login-btn:hover {
    background-color: #135f55;
    border: solid 1px black;
    transform: scale(0.8);
}


.login-btn:active {
    background-color: #135f55;
    border: solid 1px black;
    transform: scale(0.8);
}


.footer {
    width: 100%;
    padding: 1% 0%;
    text-align: center;
    margin-top: 2%; /* Ensure some space between content and footer */
    position: relative; /* Use relative positioning */
    color: #f6f5f5;
}


/* -------------------------- */
/* --------- MOBILE --------- */
/* -------------------------- */


@media (max-width: 470px){
    .patient-file-background {
        display: block;
        background-image: url("http://medescreen.co.za/wp-content/uploads/2024/04/hero-image.webp");
        width: 100%;
        min-height: none; /* Ensures the background image covers the full viewport */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
    }
    
    .file-background-filter {
        display: block;
        width: 100%;
        min-height: none; /* Same change here to cover the entire viewport */
        background-color: rgba(5, 38, 34, 0.8); /* Ensures a dark overlay on top of the background */
    }
    
    .patient-file-page { 
        width: 100%;
        height: auto; /* Ensure the height adjusts to content */
        padding-bottom: 10px; /* Add some padding at the bottom */
        position: relative; /* Switch to relative */
    }
    
    
    .header {
        position: relative;
        width: 100%;
        margin: 0% 0% 2% 0%;
        padding: 2% 0% 2% 0%;
    }
    
    .header p{
        position: relative;
        text-align: center;
        color: #ffffff;
        margin: 1% 0% 1% 0% ;
        font-size: 14px;
    }
    
    
    .mes-logo {
        position: relative;
        width: 70%;
        margin: 5% 15% 2% 15%;
    }
    
    
    .login-form {
        position: relative;
        width: 80%;
        margin: 8% 10% 2% 10%;
    }

    .Terms{
        position: relative;
        width: 100%;
        margin: 10% 0% 10% 0%;
        text-align: center;
        color: white;
        overflow-y: auto;
        height: 140px;
    }
    
    
    .login-form-input {
        position: relative;
        width: 76%;
        margin: 2% 10% 2% 10%;
        border-radius: 8px;
        padding: 2% 2% 2% 2%;
        border: solid 1px black;
    }
    
    .login-form-input:active {
        border: #105249 solid 1.5px;
        box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
    }
    
    .login-form h3{
        position: relative;
        margin: 2% 0% 10% 0%;
        font-size: 22px;
        text-align: center;
        color: #fff;
    }
    
    
    .login-btn {
        position: relative;
        width: 60%;
        margin: 2% 20% 2% 20%;
        padding: 2% 2% 2% 2%;
        background-color: #105249;
        color: white;
        font-size: 17px;
        font-weight: bold;
        border-radius: 8px;
        cursor: pointer;
        border: solid 1px black;
    }
    
    .login-btn:hover {
        background-color: #135f55;
        border: solid 1px black;
        transform: scale(0.8);
    }


    .patient-file-checkbox-container{
        position: relative;
        width: 82%;
        color: white;
        font-size: 15px;
        margin: 9% 9% 9% 9%;
    }
        
    .login-btn:active {
        background-color: #135f55;
        border: solid 1px black;
        transform: scale(0.8);
    }
    
    
    .footer {
        width: 100%;
        padding: 2% 0%;
        text-align: center;
        margin-top: 30px; /* Ensure some space between content and footer */
        position: relative; /* Use relative positioning */
        color: #f6f5f5;
        font-size: 13px;
    }
}