.medical-test-page {
    position: relative;
    width: 100%;
    font-family: "Arial", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f3f2f2;
    color: #030303;
}


.img-container {
    position: relative;
    width: 20%;
    margin: 2% 40% 1% 40%;
}


.img {
    position: relative;
    width: 100%;
}


.questionnaire-section {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 4% 0% 2% 0%;
}


h1 {
    text-align: center;
    font-size: 22px;
    margin: 2% 2% 1% 2%;
    color: #000000;
}


.questionnaire-btn-container {
    position: relative;
    width: 80%;
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
    margin: 3% 10% 2% 10%;
}


.questionnaire-btn {
    position: relative;
    width: 80%;
    margin: 3% 10% 3% 10%;
    padding: 3% 3% 3% 3%;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    background-color: #105249;
    border: solid #000000 1px;
    color: white;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
}


.questionnaire-btn:hover{
    background-color: #CCE1DA;
    border: solid 1px #000000;
    color: #000000;
    transform: scale(0.8);
    -webkit-box-shadow: 6px 6px 4px 2px #000000;
    -moz-box-shadow: 6px 6px 4px 2px #000000;
    box-shadow: 6px 6px 4px 2px #000000;
}


.questionnaire-btn:active{
    background-color: #CCE1DA;
    border: solid 1px #000000;
    color: #000000;
    transform: scale(0.8);
    -webkit-box-shadow: 6px 6px 4px 2px #000000;
    -moz-box-shadow: 6px 6px 4px 2px #000000;
    box-shadow: 6px 6px 4px 2px #000000;
}



.questionnaire-submit-container {
    position: relative;
    width: 14%;
    text-align: center;
    margin: 3% 43% 2% 43%;
}


.questionnaire-submit-btn {
    position: relative;
    width: 100%;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    background-color: #FFB54F;
    border: solid 1px #000000;
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    padding: 8% 2% 8% 2%;
}


.questionnaire-submit-btn:hover {
    background-color: #FFB54F;
    border: solid 1px #000000;
    color: #ffffff;
    transform: scale(0.8);
    border: solid 1px #000000;
    -webkit-box-shadow: 6px 6px 4px 2px #3b3b3b;
    -moz-box-shadow: 6px 6px 4px 2px #3b3b3b;
    box-shadow: 6px 6px 4px 2px #3b3b3b;
}

.questionnaire-submit-btn:active {
    background-color: #FFB54F;
    border: solid 1px #000000;
    color: #ffffff;
    transform: scale(0.8);
    border: solid 1px #000000;
    -webkit-box-shadow: 6px 6px 4px 2px #3b3b3b;
    -moz-box-shadow: 6px 6px 4px 2px #3b3b3b;
    box-shadow: 6px 6px 4px 2px #3b3b3b;
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}


.popup-content {
    position: relative;
    top: 0;
    bottom: 0;
    width: 80%;
    height: auto;
    margin: 10% 10% 10% 10%;
    background: white;
    padding: 0% 0% 0% 1%;
    border-radius: 25px;
    text-align: center;
    border: solid 1px #000000;
}

.popup-logo {
    position: relative;
    width: 40%;
    margin: 2% 30% 1% 30%;
}


.questions-container {
    position: relative;
    width: 90%;
    margin: 2% 5% 2% 5%;
    display: grid;
    grid-template-columns: 50% 50%;
    overflow-y: auto;
    height: 300px;
}


.radio-questions{
    position: relative;
    width: 90%;
    margin: 1% 5% 1% 5%;
}


.radio-questions p{
    position: relative;
    text-align: center;
    font-size: 16px;
    margin: 1% 0% 2% 0%;

}


.text-questions{
    position: relative;
    width: 90%;
    margin: 1% 5% 1% 5%;
}


.text-questions p{
    position: relative;
    text-align: center;
    font-size: 16px;
    margin: 1% 0% 2% 0%;
}


.text-questions input{
    position: relative;
    width: 50%;
    margin: 1% 21% 1% 21%;
    border-radius: 30px;
    padding: 2% 4% 2% 4%;
    border: solid 2px #105249;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.drop-dwn-questions{
    position: relative;
    width: 90%;
    margin: 1% 5% 1% 5%;
}


.drop-dwn-questions p{
    position: relative;
    text-align: center;
    font-size: 16px;
    margin: 1% 0% 2% 0%;
}


.drop-dwn-questions select{
    position: relative;
    width: 50%;
    margin: 1% 21% 1% 21%;
    border-radius: 30px;
    padding: 2% 4% 2% 4%;
    border: solid 2px #105249;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.questions-btn-container {
    position: relative;
    width: 50%;
    margin: 1% 25% 2% 25%;
    padding: 0% 0% 5% 0%;
    display: grid;
    grid-template-columns: 50% 50%;
}


.questions-cancel-btn {
    position: relative;
    width: 80%;
    margin: 0% 10% 0% 10%;
    padding: 4% 3% 4% 3%;
    font-size: 18px;
    cursor: pointer;
    border: 2px solid #105249;
    border-radius: 30px;
    background-color: #ffffff;
    color: #105249;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}


.questions-submit-btn {
    position: relative;
    width: 80%;
    margin: 0% 10% 0% 10%;
    padding: 4% 3% 4% 3%;
    font-size: 18px;
    cursor: pointer;
    border: solid 1px #000000;
    border-radius: 30px;
    background-color: #105249;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}


.scan-section{
    position: relative;
    width: 100%;
    text-align: center;
    margin: 4% 0% 2% 0%;
}


.scan-btn-container {
    position: relative;
    width: 70%;
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
    margin: 2% 15% 2% 15%;
}


.snap-btn {
    position: relative;
    width: 80%;
    margin: 2% 10% 2% 10%;
    padding: 3% 3% 3% 3%;
    font-size: 16px;
    cursor: pointer;
    border: solid 1px #000000;
    border-radius: 30px;
    background-color: #105249;
    color: white;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
}


.snap-btn:hover{
    background-color: #CCE1DA;
    border: solid 1px #000000;
    color: #000000;
    transform: scale(0.8);
    -webkit-box-shadow: 6px 6px 4px 2px #000000;
    -moz-box-shadow: 6px 6px 4px 2px #000000;
    box-shadow: 6px 6px 4px 2px #000000;
}

.snap-btn:active{
    background-color: #CCE1DA;
    border: solid 1px #000000;
    color: #000000;
    transform: scale(0.8);
    -webkit-box-shadow: 6px 6px 4px 2px #000000;
    -moz-box-shadow: 6px 6px 4px 2px #000000;
    box-shadow: 6px 6px 4px 2px #000000;
}


.scan-submit-container {
    position: relative;
    width: 30%;
    text-align: center;
    margin: 3% 35% 2% 35%;
    display: grid;
    grid-template-columns: 50% 50%;
}


.scan-submit-btn {
    position: relative;
    width: 90%;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    margin: 3% 5% 2% 5%;
    background-color: #FFB54F;
    border: solid 1px #000000;
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    padding: 6% 2% 6% 2%;
}


.scan-submit-btn :hover {
    background-color: #FFB54F;
    border: solid 1px #FFB54F;
    color: #ffffff;
    transform: scale(0.8);
    -webkit-box-shadow: 6px 6px 4px 2px #3b3b3b;
    -moz-box-shadow: 6px 6px 4px 2px #3b3b3b;
    box-shadow: 6px 6px 4px 2px #3b3b3b;
}


.scan-cancel-btn {
    position: relative;
    width: 90%;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    margin: 3% 5% 2% 5%;
    background-color: #f3f2f2;
    border: solid 1px #FFB54F;
    color: #FFB54F;
    font-weight: bold;
    font-size: 18px;
    padding: 6% 2% 6% 2%;
}

.scan-cancel-btn :hover {
    background-color: #FFB54F;
    border: solid 1px #000000;
    color: #ffffff;
    transform: scale(0.8);
    -webkit-box-shadow: 6px 6px 4px 2px #3b3b3b;
    -moz-box-shadow: 6px 6px 4px 2px #3b3b3b;
    box-shadow: 6px 6px 4px 2px #3b3b3b;
}


.display-container {
    position: relative;
    width: 60%;
    border: solid 1px #000000;
    height: 70%;
    margin: 0% 20% 2% 20%;
    border-radius: 25px;
    background-color: #322f2f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.captured-container{
    position: relative;
    width: 60%;
    border: solid 1px #000000;
    height: 70%;
    margin: 0% 20% 2% 20%;
    border-radius: 25px;
    background-color: #322f2f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* .captured-container {
    position: relative;
    width: 50%;
    height: 60%;
    margin: 3% 10% 1% 10%;
    border-radius: 25px;
    background-color: #1b1a1a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: solid 1p #000000;

} */


.stage-btn-container1 {
    position: relative;
    width: 70%;
    margin: 2% 15% 1% 15%;
    display: grid;
    grid-template-columns: 50% 50%;
}


.stage-btn-container1 .submit-btn {
    position: relative;
    display: block;
    width: 80%;
    margin: 2% 10% 1% 10%;
    padding: 3% 2% 3% 2%;
    font-size: 16px;
    cursor: pointer;
    border: solid 1px #000000;
    border-radius: 30px;
    background-color: #105249;
    color: white;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}


.stage-btn-container1 .edit-btn {
    position: relative;
    display: block;
    width: 80%;
    margin: 2% 10% 1% 10%;
    padding: 3% 2% 3% 2%;
    font-size: 16px;
    cursor: pointer;
    border: solid 1px #000000;
    border-radius: 30px;
    background-color: #CCE1DA;
    color: #000000;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}


.stage-btn-container1 .cancel-btn {
    position: relative;
    display: block;
    width: 80%;
    margin: 2% 10% 1% 10%;
    padding: 3% 2% 3% 2%;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    background-color: #ffffff;
    color: #105249;
    text-align: center;
    text-decoration: none;
    border: solid #105249 3px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}



.results-stage-btn-container .submit-btn {
    position: relative;
    display: block;
    width: 80%;
    margin: 2% 10% 1% 10%;
    padding: 3% 2% 3% 2%;
    font-size: 16px;
    cursor: pointer;
    border: solid 1px #000000;
    border-radius: 30px;
    background-color: #105249;
    color: white;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}


.results-stage-btn-container .edit-btn {
    position: relative;
    display: block;
    width: 80%;
    margin: 2% 10% 1% 10%;
    padding: 3% 2% 3% 2%;
    font-size: 16px;
    cursor: pointer;
    border: solid 1px #000000;
    border-radius: 30px;
    background-color: #CCE1DA;
    color: #000000;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}


.results-stage-btn-container .cancel-btn {
    position: relative;
    display: block;
    width: 80%;
    margin: 2% 10% 1% 10%;
    padding: 3% 2% 3% 2%;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    background-color: #ffffff;
    color: #105249;
    text-align: center;
    text-decoration: none;
    border: solid #105249 3px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}


.stage-btn-container1 button:hover {
    background-color: #36796e;
}


.image-results-container{
    position: relative;
    width: 70%;
    margin: 2% 15% 2% 15%;
    overflow-y: auto;
    height: 300px;
}


.results-stage-btn-container{
    position: relative;
    width: 80%;
    margin: 2% 10% 2% 10%;
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
}


.edit-container {
    position: relative;
    width: 80%;
    margin: 2% 10% 2% 10%;
}


.edit-container input {
    position: relative;
    width: 70%;
    margin: 2% 15% 2% 15%;
    border: #105249 2px solid;
    border-radius: 30px;
    padding: 2% 2% 2% 2%;
}

.edit-container .confirm-edit-btn {
    position: relative;
    background-color: #105249;
    width: 40%;
    margin: 2% 30% 2% 30%;
    padding: 2% 2% 2% 2%;
    border: solid 1px #000000;
    color: white;
    font-size: 16px;
    border-radius: 28px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


@media (max-width: 800px) {
    .img-container {
        position: relative;
        width: 40%;
        margin: 4% 30% 3% 30%;
    }

    .img {
        position: relative;
        width: 100%;
    }

    .scan-btn-container {
        position: relative;
        width: 70%;
        display: grid;
        grid-template-columns: 100%;
        text-align: center;
        margin: 5% 15% 2% 15%;
    }

    .snap-btn {
        position: relative;
        width: 80%;
        margin: 3% 10% 3% 10%;
        padding: 3% 3% 3% 3%;
        font-size: 18px;
        cursor: pointer;
        border: none;
        border-radius: 25px;
        background-color: #105249;
        color: white;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.3s ease;
    }

    .snap-btn:hover {
        background-color: #36796e;
    }

    .scan-submit-container {
        position: relative;
        width: 20%;
        text-align: center;
        margin: 3% 40% 2% 40%;
    }

    .scan-submit-container .submit-btn {
        position: relative;
        width: 100%;
        cursor: pointer;
        border: none;
        border-radius: 27px;
        background-color: white;
        border: solid 3px #105249;
        color: #105249;
        font-weight: bold;
        font-size: 18px;
        padding: 10% 2% 7% 2%;
    }

    .submit-btn:hover {
        background-color: #36796e;
        transform: scale(0.8);
    }

    .popup {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popup-content {
        position: relative;
        top: 0;
        bottom: 0;
        width: 70%;
        height: auto;
        margin: 2% 15% 10% 15%;
        background: white;
        padding: 2% 5% 5% 5%;
        border-radius: 25px;
        text-align: center;
    }

    .popup-logo {
        position: relative;
        width: 44%;
        margin: 1% 27% 2% 27%;
    }

    .display-container {
        position: relative;
        width: 74%;
        max-height: 40%;
        margin: 2% 13% 5% 13%;
        border-radius: 25px;
        background-color: #322f2f;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    
    .captured-container{
        position: relative;
        width: 74%;
        max-height: 40%;
        margin: 2% 13% 5% 13%;
        border-radius: 25px;
        background-color: #322f2f;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    /* .captured-container {
        position: relative;
        width: 70%;
        height: 60%;
        margin: 3% 15% 3% 15%;
        border-radius: 28px;
        background-color: #1b1a1a;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    } */


    .stage-btn-container1 {
        position: relative;
        width: 80%;
        margin: 4% 10% 1% 10%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .stage-btn-container1 button {
        position: relative;
        display: block;
        width: 80%;
        margin: 2% 10% 1% 10%;
        padding: 3% 4% 3% 4%;
        font-size: 20px;
        cursor: pointer;
        border: none;
        border-radius: 25px;
        background-color: #105249;
        color: white;
        text-align: center;
        text-decoration: none;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
    }


    .stage-btn-container1 button:hover {
        background-color: #36796e;
    }


    .popup-content .questions-container {
        position: relative;
        width: 80%;
        margin: 1% 10% 5% 10%;
        display: grid;
        grid-template-columns: 100%;
        height: 350px;
    }


    .question-btn-container {
        position: relative;
        width: 70%;
        margin: 0% 15% 2% 15%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .question-btn-container button {
        position: relative;
        width: 80%;
        margin: 0% 10% 0% 10%;
        padding: 3% 3% 3% 3%;
        font-size: 16px;
        cursor: pointer;
        border: none;
        border-radius: 25px;
        background-color: #105249;
        color: white;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.3s ease;
    }

    .question-btn-container button:hover {
        background-color: #36796e;
    }
}


/* ============================ */
/* ========== Mobile ========== */
/* ============================ */


@media (max-width: 500px) {

   
    .img-container {
        width: 70%;
        margin: 7% 15% 1% 15%;
    }
    

    .questionnaire-section {
        position: relative;
        width: 100%;
        text-align: center;
        margin: 10% 0% 2% 0%;
    }
    
    
    h1 {
        text-align: center;
        font-size: 20px;
        margin: 2% 2% 2% 2%;
    }
    
    
    .questionnaire-btn-container {
        position: relative;
        width: 80%;
        display: grid;
        grid-template-columns: 100%;
        text-align: center;
        margin: 5% 10% 2% 10%;
    }
    
    
    .questionnaire-btn {
        width: 80%;
        margin: 3% 10% 3% 10%;
        padding: 4% 3% 4% 3%;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        border-radius: 30px;
        background-color: #105249;
        border: solid #000000 1px;
        color: white;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.3s ease;
    }
    
    
    .questionnaire-btn:hover{
        background-color: #CCE1DA;
        border: solid 1px #000000;
        color: #000000;
        transform: scale(0.8);
        -webkit-box-shadow: 6px 6px 4px 2px #000000;
        -moz-box-shadow: 6px 6px 4px 2px #000000;
        box-shadow: 6px 6px 4px 2px #000000;
    }
    

    .questionnaire-submit-container {
        position: relative;
        width: 30%;
        text-align: center;
        margin: 12% 35% 2% 35%;
    }
    
    
    .questionnaire-submit-btn {
        position: relative;
        width: 100%;
        cursor: pointer;
        border: none;
        border-radius: 30px;
        background-color: #FFB54F;
        border: solid 1px #000000;
        color: #ffffff;
        font-weight: bold;
        font-size: 18px;
        padding: 8% 0% 8% 0%;
    }
    
    
    .questionnaire-submit-btn:hover {
        background-color: #FFB54F;
        border: solid 1px #000000;
        color: #ffffff;
        transform: scale(0.8);
        -webkit-box-shadow: 6px 6px 4px 2px #3b3b3b;
        -moz-box-shadow: 6px 6px 4px 2px #3b3b3b;
        box-shadow: 6px 6px 4px 2px #3b3b3b;
    }

   
    .popup-content {
        position: relative;
        top: 0;
        bottom: 0;
        width: 84%;
        padding: 1% 2% 3% 2%;
        height: 600px;
        margin: 10% 10% 10% 10%;
        background: white;
        border-radius: 25px;
        text-align: center;
        overflow-y: auto;
        border: #000000 solid 1px;
    }
    
    .popup-logo {
        position: relative;
        width: 70%;
        margin: 5% 15% 3% 15%;
    }
    

    .questions-container {
        position: relative;
        width: 90%;
        margin: 5% 5% 2% 5%;
        display: grid;
        grid-template-columns: 50% 50%;
        overflow-y: auto;
        height: 300px;
    }


    .questions-btn-container {
        position: relative;
        width: 50%;
        margin: 1% 25% 2% 25%;
        display: grid;
        grid-template-columns:100%;
        padding: 0% 0% 2% 0%;
    }
    
    
    .questions-cancel-btn {
        position: relative;
        width: 80%;
        margin: 2% 10% 2% 10%;
        padding: 4% 3% 4% 3%;
        font-size: 16px;
        cursor: pointer;
        border: 2px solid #105249;
        border-radius: 30px;
        background-color: #ffffff;
        color: #105249;
        text-align: center;
        text-decoration: none;
        font-weight: bold;
        transition: background-color 0.3s ease;
    }
    
    
    .questions-submit-btn {
        position: relative;
        width: 80%;
        margin: 2% 10% 2% 10%;
        padding: 4% 3% 4% 3%;
        font-size: 16px;
        cursor: pointer;
        border: solid 1px #000000;
        border-radius: 30px;
        background-color: #105249;
        color: #ffffff;
        text-align: center;
        text-decoration: none;
        font-weight: bold;
        transition: background-color 0.3s ease;
    }
    
    
    .radio-questions{
        position: relative;
        width: 90%;
        margin: 1% 5% 1% 5%;
    }
    
    
    .radio-questions p{
        position: relative;
        text-align: center;
        font-size: 16px;
        margin: 1% 0% 2% 0%;
    }
    
    
    .text-questions{
        position: relative;
        width: 90%;
        margin: 1% 5% 1% 5%;
    }
    
    
    .text-questions p{
        position: relative;
        text-align: center;
        font-size: 16px;
        margin: 1% 0% 2% 0%;
    }
    
    
    .text-questions input{
        position: relative;
        width: 70%;
        margin: 1% 15% 1% 15%;
        border-radius: 30px;
        padding: 2% 4% 2% 4%;
        border: solid 2px #105249;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    
    .drop-dwn-questions{
        position: relative;
        width: 90%;
        margin: 1% 5% 1% 5%;
    }
    
    
    .drop-dwn-questions p{
        position: relative;
        text-align: center;
        font-size: 16px;
        margin: 1% 0% 2% 0%;
    }
    
    
    .drop-dwn-questions select{
        position: relative;
        width: 70%;
        margin: 1% 15% 1% 15%;
        border-radius: 30px;
        padding: 2% 4% 2% 4%;
        border: solid 2px #105249;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    

    .scan-section{
        position: relative;
        width: 100%;
        text-align: center;
        margin: 4% 0% 2% 0%;
    }
    
    
    .scan-btn-container {
        position: relative;
        width: 80%;
        display: grid;
        grid-template-columns: 100%;
        text-align: center;
        margin: 2% 10% 2% 10%;
    }
    
    
    .snap-btn {
        position: relative;
        width: 80%;
        margin: 2% 10% 2% 10%;
        padding: 5% 3% 5% 3%;
        font-size: 16px;
        cursor: pointer;
        border: solid 1px #000000;
        border-radius: 30px;
        background-color: #105249;
        color: white;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.3s ease;
    }

    
    .snap-btn:hover{
        background-color: #CCE1DA;
        border: solid 1px #000000;
        color: #000000;
        transform: scale(0.8);
        -webkit-box-shadow: 6px 6px 4px 2px #000000;
        -moz-box-shadow: 6px 6px 4px 2px #000000;
        box-shadow: 6px 6px 4px 2px #000000;
    }


    .scan-submit-container {
        position: relative;
        width: 40%;
        text-align: center;
        margin: 3% 30% 2% 30%;
        display: grid;
        grid-template-columns: 100%;
    }
    
    .scan-submit-btn {
        position: relative;
        width: 90%;
        cursor: pointer;
        border: none;
        border-radius: 30px;
        margin: 5% 5% 5% 5%;
        background-color: #FFB54F;
        border: solid 1px #000000;
        color: #ffffff;
        font-weight: bold;
        font-size: 18px;
        padding: 8% 2% 8% 2%;
    }
    
    .scan-submit-btn :hover {
        background-color: #FFB54F;
        border: solid 1px #030303;
        color: #ffffff;
        transform: scale(0.8);
        -webkit-box-shadow: 6px 6px 4px 2px #3b3b3b;
        -moz-box-shadow: 6px 6px 4px 2px #3b3b3b;
        box-shadow: 6px 6px 4px 2px #3b3b3b;
    }
    
    
    .scan-cancel-btn {
        position: relative;
        width: 90%;
        cursor: pointer;
        border: none;
        border-radius: 30px;
        margin: 5% 5% 5% 5%;
        background-color: #f3f2f2;
        border: solid 3px #FFB54F;
        color: #FFB54F;
        font-weight: bold;
        font-size: 18px;
        padding: 8% 2% 8% 2%;
        -webkit-box-shadow: 0 0 0 1px #000000;
        -moz-box-shadow: 0 0 0 1px #000000;
        box-shadow: 0 0 0 1px #000000;
    }
    

    .scan-cancel-btn :hover {
        background-color: #FFB54F;
        border: solid 1px #000000;
        color: #ffffff;
        transform: scale(0.8);
        -webkit-box-shadow: 6px 6px 4px 2px #3b3b3b;
        -moz-box-shadow: 6px 6px 4px 2px #3b3b3b;
        box-shadow: 6px 6px 4px 2px #3b3b3b;
    }


    .stage-btn-container1 {
        position: relative;
        width: 80%;
        margin: 10% 10% 1% 10%;
        display: grid;
        grid-template-columns: 100%;
    }

    .stage-btn-container1 button {
        position: relative;
        display: block;
        width: 90%;
        margin: 2% 5% 5% 5%;
        padding: 3% 4% 3% 4%;
        font-size: 20px;
        cursor: pointer;
        border: none;
        border-radius: 25px;
        background-color: #105249;
        color: white;
        text-align: center;
        text-decoration: none;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
    }


    .stage-btn-container1 button:hover {
        background-color: #36796e;
    }


    .display-container {
        position: relative;
        width: 80%;
        max-height: 40%;
        margin: 5% 10% 9% 10%;
        border-radius: 25px;
        background-color: #322f2f;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .captured-container{
        position: relative;
        width: 80%;
        max-height: 40%;
        margin: 5% 10% 9% 10%;
        border-radius: 25px;
        background-color: #322f2f;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .stage-btn-container1 {
        position: relative;
        width: 70%;
        margin: 2% 15% 1% 15%;
        display: grid;
        grid-template-columns: 100%;
    }
    
    
    .stage-btn-container1 .submit-btn {
        position: relative;
        display: block;
        width: 80%;
        margin: 2% 10% 1% 10%;
        padding: 5% 2% 5% 2%;
        font-size: 16px;
        cursor: pointer;
        border: solid 1px #000000;
        border-radius: 30px;
        background-color: #105249;
        color: white;
        text-align: center;
        text-decoration: none;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
    }
    
    
    .stage-btn-container1 .edit-btn {
        position: relative;
        display: block;
        width: 80%;
        margin: 2% 10% 1% 10%;
        padding: 5% 2% 5% 2%;
        font-size: 16px;
        cursor: pointer;
        border: solid 1px #000000;
        border-radius: 30px;
        background-color: #CCE1DA;
        color: #000000;
        text-align: center;
        text-decoration: none;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
    }
    
    
    .stage-btn-container1 .cancel-btn {
        position: relative;
        display: block;
        width: 80%;
        margin: 2% 10% 1% 10%;
        padding: 5% 2% 5% 2%;
        font-size: 16px;
        cursor: pointer;
        border: none;
        border-radius: 30px;
        background-color: #ffffff;
        color: #105249;
        text-align: center;
        text-decoration: none;
        border: solid #105249 3px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
    }
    
    
    
    .results-stage-btn-container .submit-btn {
        position: relative;
        display: block;
        width: 80%;
        margin: 2% 10% 1% 10%;
        padding: 5% 2% 5% 2%;
        font-size: 16px;
        cursor: pointer;
        border: solid 1px #000000;
        border-radius: 30px;
        background-color: #105249;
        color: white;
        text-align: center;
        text-decoration: none;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
    }
    
    
    .results-stage-btn-container .edit-btn {
        position: relative;
        display: block;
        width: 80%;
        margin: 2% 10% 1% 10%;
        padding: 5% 2% 5% 2%;
        font-size: 16px;
        cursor: pointer;
        border: solid 1px #000000;
        border-radius: 30px;
        background-color: #CCE1DA;
        color: #000000;
        text-align: center;
        text-decoration: none;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
    }
    
    
    .results-stage-btn-container .cancel-btn {
        position: relative;
        display: block;
        width: 80%;
        margin: 2% 10% 1% 10%;
        padding: 5% 2% 5% 2%;
        font-size: 16px;
        cursor: pointer;
        border: none;
        border-radius: 30px;
        background-color: #ffffff;
        color: #105249;
        text-align: center;
        text-decoration: none;
        border: solid #105249 3px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;
    }
    
    
    .stage-btn-container1 button:hover {
        background-color: #36796e;
    }
    
    
    .image-results-container{
        position: relative;
        width: 80%;
        margin: 2% 10% 2% 10%;
    }
    
    
    .results-stage-btn-container{
        position: relative;
        width: 70%;
        margin: 2% 15% 2% 15%;
        display: grid;
        grid-template-columns: 100%;
        text-align: center;
    }
    
    
    .edit-container {
        position: relative;
        width: 70%;
        margin: 2% 15% 2% 15%;
    }
    
    
    .edit-container input {
        position: relative;
        width: 70%;
        margin: 2% 15% 2% 15%;
        border: #105249 2px solid;
        border-radius: 30px;
        padding: 2% 2% 2% 2%;
    }
    
    .edit-container .confirm-edit-btn {
        position: relative;
        background-color: #105249;
        width: 50%;
        margin: 6% 25% 2% 25%;
        padding: 2% 4% 2% 4%;
        border: solid 1px #000000;
        color: white;
        font-size: 16px;
        border-radius: 30px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }


    /* submitting */

    .popup-submit {
        position: relative;
        top: 0;
        bottom: 0;
        width: 84%;
        padding: 1% 2% 3% 2%;
        height: 370px;
        margin: 10% 10% 10% 10%;
        background: white;
        border-radius: 25px;
        text-align: center;
        border: #000000 solid 1px;
    }

    .submit-popup-logo {
        position: relative;
        width: 80%;
        margin: 5% 10% 3% 10%;
    }

    .submit-load{
        position: relative;
        width: 100%;
        height: auto;
        padding: 3% 0% 2% 0%;
        margin: 0%;
    }

    .submit-load p{
        position: relative;
        text-align: center;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: bold;
        margin: 2% 5% 2% 5%;
    }

    .loading-container{
        position: relative;
        width: 80%;
        height: auto;
        margin: 8% 10% 2% 10%;
    }

    .loader{
        position: relative;
        width: 80%; /* Adjust the size as needed */
        margin: 5% 15% 5% 10%;
        height: auto; /* Adjust the size as needed */
        animation: spin 3s ease-in-out infinite;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
   
    .submit-success{
        position: relative;
        width: 100%;
        height: auto;
        padding: 3% 0% 2% 0%;
        margin: 0%;
    }

    .submit-success p{
        position: relative;
        text-align: center;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: bold;
        margin: 2% 5% 2% 5%;
    }

    .success-animation-container {
        position: relative;
        margin-top: 2%;
        display: inline-block;
        margin-top: 20px;
    }
    
    .check {
        width: 100px; /* Adjust the size as needed */
        height: 100px;
        opacity: 0; /* Initially hidden */
    }
    
    .check:first-child {
        animation: rotateAndDisappear 6s ease-in-out infinite; /* Repeats infinitely */
    }
    
    .check:last-child {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0; /* Initially hidden */
        animation: slideArrow 6s ease-in-out infinite; /* Repeats infinitely with a delay */
    }
    
    @keyframes rotateAndDisappear {
        0% {
            transform: rotate(0deg);
            opacity: 1;
        }
        20% {
            transform: rotate(360deg);
            opacity: 1;
        }
        30% {
            opacity: 0; /* Fades out the check mark */
        }
        100% {
            opacity: 0; /* Keep hidden for the rest of the cycle */
        }
    }
    
    @keyframes slideArrow {
        0%, 30% {
            transform: translateX(-100px);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        70% {
            transform: translateX(100px); /* Move arrow to the right */
            opacity: 1;
        }
        100% {
            opacity: 0; /* Fade out */
            transform: translateX(100px); /* Keep at the end position */
        }
    }

    .submit-error{
        position: relative;
        width: 100%;
        height: auto;
        padding: 3% 0% 2% 0%;
        margin: 13% 0% 0% 0%;
    }

    .submit-error p{
        position: relative;
        text-align: center;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: bold;
        margin: 1% 5% 2% 5%;
    }

    .try-again-btn{
        position: relative;
        display: block;
        width: 70%;
        margin: 15% 15% 5% 15%;
        padding: 5% 2% 5% 2%;
        font-size: 16px;
        cursor: pointer;
        border: none;
        border-radius: 30px;
        background-color: #105249;
        color: #fff;
        text-align: center;
        text-decoration: none;
        border: solid #000000 1px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;   
    }

    .try-again-btn:hover{
        background-color: #CCE1DA;
        border: solid 1px #000000;
        color: #000000;
        transform: scale(0.8);
        -webkit-box-shadow: 6px 6px 4px 2px #000000;
        -moz-box-shadow: 6px 6px 4px 2px #000000;
        box-shadow: 6px 6px 4px 2px #000000;
    }


    .submit-failed{
        position: relative;
        width: 100%;
        height: auto;
        padding: 3% 0% 2% 0%;
        margin: 15% 0% 0% 0%;
    }

    .submit-failed p{
        position: relative;
        text-align: center;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: bold;
        margin: 2% 5% 3% 5%;
    }

    .redo-btn{
        position: relative;
        display: block;
        width: 70%;
        margin: 15% 15% 5% 15%;
        padding: 5% 2% 5% 2%;
        font-size: 16px;
        cursor: pointer;
        border: none;
        border-radius: 30px;
        background-color: #105249;
        color: #fff;
        text-align: center;
        text-decoration: none;
        border: solid #000000 1px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s ease;   
    }

    .redo-btn:hover{
        background-color: #CCE1DA;
        border: solid 1px #000000;
        color: #000000;
        transform: scale(0.8);
        -webkit-box-shadow: 6px 6px 4px 2px #000000;
        -moz-box-shadow: 6px 6px 4px 2px #000000;
        box-shadow: 6px 6px 4px 2px #000000;
    }
}