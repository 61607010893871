.background {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    background-image: url("http://medescreen.co.za/wp-content/uploads/2024/05/the-sollution.webp");
    width: 100%;
    height: 100%;
    margin: 0% 0% 0% 0%;
    z-index: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.background-filter {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(5, 38, 34, 0.8);
    z-index: 1;
    background-position: center;
}


.results-page {
    position: relative;
    width: 70%;
    margin: 3% 15% 0% 15%;
    padding: 1% 2% 3% 2%;
    background-color: white;
    border-radius: 28px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.results-image-container {
    position: relative;
    width: 30%;
    margin: 1% 35% 1% 35%;
}

.results-image {
    position: relative;
    width: 100%;
}


h2 {
    position: relative;
    text-align: center;
    font-size: 17px;
}


h3{
    position: relative;
    text-align: center;
    font-size: 16px;
}

.final-btn-container {
    position: relative;
    width: 30%;
    text-align: center;
    margin: 3% 35% 2% 35%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.final-btn-container .new-test-btn {
    position: relative;
    width: 90%;
    margin: 0% 5% 0% 5%;
    cursor: pointer;
    border: solid 1px #000000;
    border-radius: 23px;
    background-color: #105249;
    color: white;
    font-weight: bold;
    font-size: 16px;
    padding: 4% 2% 4% 2%;
}


.save-btn-container .new-test-btn:hover {
    background-color: #36796e;
    transform: scale(0.8);
    border: solid 1px #000000;
}


.final-btn-container .logout-btn {
    position: relative;
    width: 90%;
    margin: 0% 5% 0% 5%;
    cursor: pointer;
    border-radius: 30px;
    background-color: #FFB54F;
    border: solid 1px #000000;
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    padding: 8% 2% 8% 2%;
}


.save-btn-container .logout-btn:hover {
    background-color: #36796e;
    transform: scale(0.8);
    border: solid 1px #000000;
}


@media (max-width: 800px) {

    .results-page {
        position: relative;
        width: 80%;
        margin: 10% 8% 0% 8%;
        padding: 1% 2% 3% 2%;
        background-color: white;
        border-radius: 28px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .results-image-container {
        position: relative;
        width: 50%;
        margin: 2% 25% 2% 25%;
    }

    .results-image {
        position: relative;
        width: 100%;
    }

    .final-btn-container {
        position: relative;
        width: 30%;
        text-align: center;
        margin: 3% 35% 2% 35%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .final-btn-container .new-test-btn {
        position: relative;
        width: 90%;
        margin: 0% 5% 0% 5%;
        cursor: pointer;
        border: solid 1px #000000;
        border-radius: 23px;
        background-color: #105249;
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding: 4% 2% 4% 2%;
    }
    
    
    .save-btn-container .new-test-btn:hover {
        background-color: #36796e;
        transform: scale(0.8);
    }
    
    
    .final-btn-container .logout-btn {
        position: relative;
        width: 90%;
        margin: 0% 5% 0% 5%;
        cursor: pointer;
        border-radius: 30px;
        background-color: #FFB54F;
        border: solid 1px #000000;
        color: #ffffff;
        font-weight: bold;
        font-size: 18px;
        padding: 8% 2% 8% 2%;
    }
    
    
    .save-btn-container .logout-btn:hover {
        background-color: #36796e;
        transform: scale(0.8);
    }

}



@media (max-width: 470px) {

    .results-page {
        position: relative;
        width: 80%;
        margin: 5% 8% 0% 8%;
        padding: 1% 2% 3% 2%;
        background-color: white;
        border-radius: 28px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .results-image-container {
        position: relative;
        width: 60%;
        margin: 4% 20% 4% 20%;
    }

    .results-image {
        position: relative;
        width: 100%;
    }


    .final-btn-container {
        position: relative;
        width: 70%;
        text-align: center;
        margin: 5% 15% 1% 15%;
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .final-btn-container .new-test-btn {
        position: relative;
        width: 90%;
        margin: 0% 5% 0% 5%;
        cursor: pointer;
        border: solid 1px #000000;

        border-radius: 23px;
        background-color: #105249;
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding: 4% 2% 4% 2%;
    }
    
    
    .save-btn-container .new-test-btn:hover {
        background-color: #36796e;
        transform: scale(0.8);
    }
    
    
    .final-btn-container .logout-btn {
        position: relative;
        width: 90%;
        margin: 0% 5% 0% 5%;
        cursor: pointer;
        border-radius: 30px;
        background-color: #FFB54F;
        border: solid 1px #000000;
        color: #ffffff;
        font-weight: bold;
        font-size: 18px;
        padding: 8% 2% 8% 2%;
    }
    
    
    .save-btn-container .logout-btn:hover {
        background-color: #36796e;
        transform: scale(0.8);
    }

}