.background {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    background-image: url("http://medescreen.co.za/wp-content/uploads/2024/05/the-sollution.webp");
    width: 100%;
    height: 100%;
    margin: 0% 0% 0% 0%;
    z-index: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-filter {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(5, 38, 34, 0.8);
    z-index: 1;
    background-position: center;
}


body {
    font-family: Arial, sans-serif;
}

.consent-page {
    position: absolute;
    width: 80%;
    margin: 3% 10% 3% 10%;
    border-radius: 28px;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: solid 1px black;
}

.consent-logo {
    width: 30%;
    margin: 2% 35% 2% 35%;
}

.consent-page-container {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    margin: 2% 0% 2% 0%;
}

.patient-form-container {
    position: relative;
    width: 90%;
    margin: none;
    padding: 2% 2% 2% 2%;
    border-radius: 0px;
}

.patient-form-container h2 {
    margin: 0% 0% 3% 5%
}

.terms-container {
    position: relative;
    width: 110%;
    margin: 0% 0% 0% -10%;
    padding: 2% 0% 2% 3%;
    border-left: 1px solid #ccc;
    border-radius: 0px;
}

.terms {
    position: relative;
    width: 90%;
    margin: 2% 5% 2% 3%;
    height: 220px;
    overflow-y: auto;
    border: solid 1px black;
    border-radius: 0px;
}

.terms-container h2 {
    margin: 0% 0% 0% 0%
}

.patient-form-input {
    position: relative;
    width: 80%;
    margin: 2% 15% 2% 5%;
    border-radius: 25px;
    padding: 2% 2% 2% 2%;
    border: solid 1px black;
}

.patient-form-input:active {
    border: #105249 solid 1.5px;
    width: 83%;
    margin: 2% 12% 2% 5%;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
}

.patient-form-input:focus {
    border: #105249 solid 1.5px;
    width: 83%;
    margin: 2% 12% 2% 5%;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
}

.check-box-container {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
}

.check-box {
    position: relative;
    margin: 1% 2% 1% 2%;
}


.consent-button {
    position: relative;
    width: 30%;
    margin: 2% 35% 2% 35%;
    padding: 2% 2% 2% 2%;
    background-color: #105249;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 30px;
    cursor: pointer;
    border: solid 1px black;
}

.consent-button:hover {
    background-color: #135f55;
    border: solid 1px black;
    transform: scale(0.8);
}

.consent-button:active {
    background-color: #135f55;
    border: solid 1px black;
    transform: scale(0.8);
}


@media (max-width: 600px) {

    .consent-page {
        position: relative;
        width: 80%;
        margin: 7% 10% 3% 10%;
        border-radius: 28px;
        box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
        background-color: white;
    }

    .consent-page-container {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        justify-content: center;
        margin: 4% 0% 2% 0%;
    }

    .consent-logo {
        width: 70%;
        margin: 5% 15% 2% 15%;
    }

    .terms-container {
        position: relative;
        width: 90%;
        margin: 2% 3% 0% 3%;
        padding: 2% 2% 2% 3%;
        border: none;
        border-radius: 0px;
    }

    .consent-page-container h2 {
        font-size: 18px;
    }

    .terms {
        position: relative;
        width: 90%;
        margin: 2% 5% 2% 0%;
        height: 100px;
        overflow-y: auto;
    }

    .consent-button {
        position: relative;
        width: 40%;
        margin: 2% 30% 2% 30%;
        padding: 3% 2% 3% 2%;
        background-color: #105249;
        color: white;
        border: none;
        font-size: 15px;
        font-weight: bold;
        border-radius: 30px;
        cursor: pointer;
    }
}