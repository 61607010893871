.background {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    background-image: url("http://medescreen.co.za/wp-content/uploads/2024/05/the-sollution.webp");
    width: 100%;
    height: 100%;
    margin: 0% 0% 0% 0%;
    z-index: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-filter {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(5, 38, 34, 0.8);
    z-index: 1;
    background-position: center;
}

.login-container {
    position: relative;
    width: 60%;
    margin: 5% 20% 5% 20%;
    display: grid;
    grid-template-columns: 40% 60%;
    background-color: #fff;
    border: solid 1px black;
    border-radius: 28px;
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
    z-index: 2;
    opacity: 1;
}

.sideImg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: none;
}

.solutionImg {
    position: relative;
    border-radius: none;
    width: 100%;
    height: auto;
}

.form-container {
    position: relative;
    padding: 15% 10% 5% 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 70%;
    margin: 5% 15% 5% 15%;
}

.form-container h1 {
    font-size: 20px;
    color: #424141;
}

.form-container form {
    width: 100%;
    padding: 2% 5%;
}

.form-container input {
    width: 70%;
    padding: 2% 5% 2% 5%;
    margin: 3% 10% 2% 10%;
    border: solid 1px black;
    border-radius: 20px;
}

.form-container button {
    width: 40%;
    padding: 2% 2%;
    margin: 5% 30% 2% 30%;
    background-color: #105249;
    color: white;
    border: solid 1px black;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    font-weight: bold;
}

.form-container button:active {
    background-color: #36796e;
    border: solid 1px black;
    transform: scale(0.8);
}

/* tables */
@media (max-width: 820px) {

    .login-container {
        position: relative;
        width: 80%;
        margin: 15% 10% 7% 10%;
        display: grid;
        grid-template-columns: 40% 60%;
        background-color: #fff;
        border-radius: 28px;
        box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
    }

    .sideImg {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: none;
    }

    .solutionImg {
        position: relative;
        border-radius: none;
        width: 100%;
        height: auto;
    }

    .form-container {
        position: relative;
        padding: 15% 5% 5% 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .logo {
        width: 80%;
        margin: 3% 10% 6% 10%;
    }

    .form-container h1 {
        font-size: 23px;
        color: #424141;
    }

    .form-container form {
        width: 100%;
        padding: 1% 5%;
    }

    .form-container input {
        width: 70%;
        padding: 2% 5% 2% 5%;
        margin: 3% 10% 2% 10%;
        border: 1px solid #ccc;
        border-radius: 20px;
    }

    .form-container button {
        width: 40%;
        padding: 2% 2%;
        margin: 5% 30% 2% 30%;
        background-color: #105249;
        color: white;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.1s ease;
    }

    .form-container button:active {
        background-color: #36796e;
        border: solid 1px black;
        transform: scale(0.8);
    }

}


@media (max-width: 700px) {

    .login-container {
        position: relative;
        width: 70%;
        margin: 20% 15% 5% 15%;
        display: grid;
        grid-template-columns: 100%;
        background-color: #fff;
        border-radius: 28px;
        box-shadow: 0 5px 9px rgba(0, 0, 0, 0.1);
    }

    .sideImg {
        position: relative;
        display: none;
        justify-content: center;
        align-items: center;
        border-radius: none;
    }

    .solutionImg {
        position: relative;
        border-radius: none;
        width: 100%;
        height: auto;
    }

    .form-container {
        position: relative;
        padding: 7% 5% 5% 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .logo {
        width: 80%;
        margin: 5% 10% 2% 10%;
    }

    .form-container h1 {
        font-size: 26px;
        color: #424141;
    }

    .form-container form {
        width: 100%;
        padding: 1% 5%;
    }

    .form-container input {
        width: 70%;
        padding: 2% 5% 2% 5%;
        margin: 3% 10% 2% 10%;
        border: 1px solid #ccc;
        border-radius: 20px;
    }

    .form-container button {
        width: 40%;
        padding: 2% 2%;
        margin: 5% 30% 2% 30%;
        background-color: #105249;
        color: white;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.1s ease;
        font-size: 20px;
    }

    .form-container button:active {
        background-color: #36796e;
        border: solid 1px black;
        transform: scale(0.8);
    }
}

@media (max-width: 470px) {

    .login-container {
        position: relative;
        width: 70%;
        padding: 2% 1% 5% 1%;
        margin: 27% 14% 5% 14%;
        display: grid;
        grid-template-columns: 100%;
        background-color: #ffffff;
        border-radius: 28px;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    }

    .sideImg {
        position: relative;
        display: none;
        justify-content: center;
        align-items: center;
        border-radius: none;
    }

    .solutionImg {
        position: relative;
        border-radius: none;
        width: 100%;
        height: auto;
    }

    .form-container {
        position: relative;
        padding: 7% 5% 5% 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .logo {
        width: 80%;
        margin: 5% 10% 8% 10%;
    }

    .form-container h1 {
        font-size: 23px;
        color: #424141;
    }

    .form-container form {
        width: 100%;
        padding: 3% 5%;
    }

    .form-container input {
        width: 80%;
        padding: 3% 5% 3% 5%;
        margin: 5% 5% 2% 5%;
        border: 1px solid #ccc;
        border-radius: 20px;
        font-size: 16px;
    }

    .form-container button {
        width: 40%;
        padding: 4% 2% 4% 2%;
        margin: 7% 30% 2% 30%;
        background-color: #105249;
        color: white;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.1s ease;
        font-size: 16px;
    }

    .form-container button:active {
        background-color: #36796e;
        border: solid 1px black;
        transform: scale(0.8);
    }
}